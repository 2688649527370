import { Module } from 'vuex';
import { Family, User, Dependent } from '@/models';
import { db, auth } from '@/firebase';
import {
  doc,
  getDoc,
  getDocs,
  addDoc,
  collection,
  updateDoc,
  query,
  serverTimestamp,
  orderBy,
  limit
} from 'firebase/firestore';

// eslint-disable-next-line
const familyModule: Module<any, any> = {
  namespaced: true,

  state: {
    family: null
  },
  mutations: {
    setFamily(state, familyData) {
      state.family = familyData;
    },
    setFamilyAdmin(state, adminData) {
      state.family = { ...state.family, ...adminData };
    },
    setJournalSettings(state, settings) {
      const family: Family = { ...state.family };
      family.journalSettings = { ...settings };
      state.family = { ...family };
    },
    setHomeSettings(state, settings) {
      const family: Family = { ...state.family };
      family.homeSettings = [...settings];
      state.family = { ...family };
    },
    setDependents(state, dependents) {
      const family: Family = { ...state.family };
      family.dependents = [...dependents];
      state.family = { ...family };
    }
  },
  actions: {
    async createFamily(context, data) {
      try {
        const uid: string = this.getters['auth/getAuthUser'].uid;
        const userInfo: User = this.getters['user/getUser'];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const membersObj: any = {};
        membersObj[uid] = {
          email: userInfo.email,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          ...(userInfo.nickname ? { nickname: userInfo.nickname } : {})
        };
        const famRef = await addDoc(collection(db, 'families'), {
          familyName: data.familyName,
          familyCity: data.familyCity,
          members: membersObj
        });
        await updateDoc(doc(db, 'users', uid), {
          familyId: famRef.id,
          familyName: data.familyName
        });
        await this.dispatch('family/loadFamily', famRef.id);
        const res = await auth.currentUser?.getIdToken(true);
        return res;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async loadFamily(context, familyId) {
      const docRef = doc(db, 'families', familyId);
      try {
        const family = await getDoc(docRef);
        if (family.exists()) {
          context.commit('setFamily', {
            ...family.data(),
            familyId: family.id
          });
          const idTokenResults = await auth.currentUser?.getIdTokenResult();
          if (
            idTokenResults?.claims.familyRole === 'admin' ||
            idTokenResults?.claims.familyRole === 'creator'
          ) {
            // eslint-disable-next-line
            const loadedInvites: any[] = [];
            const q = query(
              collection(db, 'families', familyId, 'invites'),
              orderBy('modifyTimestamp', 'desc'),
              limit(10)
            );
            const snapshot = await getDocs(q);
            if (!snapshot.empty) {
              snapshot.forEach((invite) => {
                loadedInvites.push({ ...invite.data(), id: invite.id });
              });
            }
            const privateData = await getDoc(
              doc(db, 'families', familyId, 'private/private')
            );
            context.commit('setFamilyAdmin', {
              invites: loadedInvites,
              private: privateData.data()
            });
          }
        } else {
          console.log('Family doc does not exist.');
        }
      } catch (error) {
        console.log(error);
      }
    },
    async updateFamily(context, familyData) {
      try {
        const promise = await updateDoc(
          doc(db, 'families', familyData.familyId),
          {
            familyName: familyData.familyName,
            familyCity: familyData.familyCity
          }
        );
        context.commit('setFamily', { ...familyData });
        return promise;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async addDependent(context, dependent) {
      // only add if it dependent does not already exist with same nickname
      if (
        context.state.family.dependents &&
        context.state.family.dependents.length &&
        context.state.family.dependents.some(
          (dep: Dependent) => dep.nickname === dependent.nickname
        )
      ) {
        console.log('Dependent already exists.', dependent.nickname);
        return null;
      }
      let newDepArray: Dependent[] = [];
      if (
        context.state.family.dependents &&
        context.state.family.dependents.length
      ) {
        newDepArray = [...context.state.family.dependents, dependent];
      } else {
        newDepArray = [dependent];
      }
      try {
        const promise = await updateDoc(
          doc(db, 'families', context.rootGetters['family/getFamily'].familyId),
          {
            dependents: newDepArray
          }
        );
        context.commit('setDependents', newDepArray);
        return promise;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async removeDependent(context, depName: string) {
      const newDepArray = context.state.family.dependents.filter(
        (dep: Dependent) => dep.nickname !== depName
      );
      try {
        const promise = await updateDoc(
          doc(db, 'families', context.rootGetters['family/getFamily'].familyId),
          {
            dependents: newDepArray
          }
        );
        context.commit('setDependents', newDepArray);
        return promise;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async updateJournalSettings(context, settings) {
      try {
        const promise = await updateDoc(
          doc(db, 'families', context.rootGetters['family/getFamily'].familyId),
          {
            journalSettings: { ...settings }
          }
        );
        context.commit('setJournalSettings', { ...settings });
        return promise;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async updateHomeSettings(context, settings) {
      try {
        const promise = await updateDoc(
          doc(db, 'families', context.rootGetters['family/getFamily'].familyId),
          {
            homeSettings: [...settings]
          }
        );
        context.commit('setHomeSettings', [...settings]);
        return promise;
      } catch (error) {
        console.log(error);
        return null;
      }
    },
    async inviteMember(context, email) {
      try {
        await addDoc(
          collection(
            db,
            'families',
            context.rootGetters['family/getFamily'].familyId,
            'invites'
          ),
          {
            email,
            familyId: context.rootGetters['family/getFamily'].familyId,
            familyName: context.rootGetters['family/getFamily'].familyName,
            adminName: context.rootGetters['user/getUser'].firstName,
            status: 'submitted',
            modifyTimestamp: serverTimestamp()
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  },
  getters: {
    getFamily(state) {
      return state.family;
    },
    getJournalSettings(state) {
      return state.family.journalSettings;
    },
    getHomeSettings(state) {
      return state.family.homeSettings;
    }
  }
};

export default familyModule;
